import React from 'react';
import Layout from 'components/layout';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import { Container, ButtonWork } from '../../content/welcome/welcome.css';

const Index = () => (
  <Layout>
    <Container>
      <Fade bottom duration={1000} delay={300} distance="50px">
        <h1>THIBAULT DREVON</h1>
      </Fade>
      <Fade bottom duration={1000} delay={400} distance="50px">
        <h2>
          PRODUCT DESIGNER
          <br />
          FRONT DEVELOPER
        </h2>
      </Fade>
      <Fade bottom duration={1000} delay={500} distance="50px">
        <h3>Portfolio 2020</h3>
      </Fade>
      <Fade bottom duration={1000} delay={600} distance="50px">
        <Link to="/work">
          <ButtonWork>See my work</ButtonWork>
        </Link>
      </Fade>
    </Container>
  </Layout>
);

export default Index;
