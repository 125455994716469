import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  margin-top: 10rem;
  text-align: center;
  ${MEDIA.PHONE`
    margin-top: 5rem;
  `};

  h1 {
    text-transform: uppercase;
    font-size: 1.8rem;
    letter-spacing: 1rem;
    margin-bottom: 3rem;
    ${MEDIA.PHONE`
      font-size: 1.2rem;
      margin-bottom: 3rem;
    `};
  }

  h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 6rem;
    line-height: 8rem;
    font-weight: 600;
    margin-bottom: 3rem;
    ${MEDIA.PHONE`
      font-size: 2.4rem;
      line-height: 3.4rem;
      padding: 0 2rem;
      margin-bottom: 3rem;
    `};
  }

  h3 {
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 1rem;
    margin-bottom: 10rem;
    ${MEDIA.PHONE`
      font-size: 1.2rem;
      margin-bottom: 10rem;
    `};
  }
`;

export const ButtonWork = styled.button` {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 2rem 5rem;
  border-radius: 200px;
  border: none;
  cursor: pointer; 
  background-color: #F0F0F0;
  transition: 0.5s;
  ${MEDIA.PHONE`
    padding: 2rem 5rem;
    font-size: 1.4rem;
  `};
  :hover {
    background-color: #D7D7D7;
  }
  :focus {
    outline: none;
  }
  :active {
      transform: scale(0.8);
  }
`;
